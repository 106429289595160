<template>
  <a-card>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
        class="panel panel-default panel-search"
    >
      <a-col flex="auto">
        <a-form-model-item
            label="名称"
        >
          <a-input
              placeholder="请输入名称"
              v-model="search_form.activity_name"
          />
        </a-form-model-item>
        <a-form-model-item
            label="品牌（主体）"
        >
          <a-select
              v-model="search_form.principal_id"
              placeholder="请选择品牌（主体）"
              show-search
              option-filter-prop="children"
              style="width:205px"
              @change="onChangePrincipal"
          >
            <a-select-option
                v-for="item in principal_id_list"
                :key="item.id"
                :name="item.principal_name"
            >
              {{ item.principal_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
            label="活动起始时间"
        >
          <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              :allowClear="false"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              inputReadOnly
              @change="onChangeDate"
              style="width:100%"
              v-model="search_form.time"
          />
        </a-form-model-item>
      </a-col>
      <a-col class="tools">
        <a-button
            type="primary"
            @click="bindSearch"
            class="mr-10"
        >查询
        </a-button>
        <a-button
            @click="resetSearch"
        >重置
        </a-button>
      </a-col>
    </a-form-model>


    <a-table
        :columns="columns"
        :data-source="data_list"
        :pagination="false"
        :rowKey="(record, index) => index"
        :loading="loading"
        class="mt-40p"
    >
      <div slot='dealer_count' slot-scope='text, record'>
        <div class='row_box'><span>经销商数量：</span>{{ record.dealer_count || '0' }}</div>
        <div class='row_box'><span>绑定微信：</span>{{ record.wechat_fail_count || '0' }}</div>
        <div class='row_box'><span>媒体帐号数：</span>{{ record.media_count || '0' }}</div>
        <div class='row_box'><span>创作者授权异常：</span>{{ record.p1_fail_count || '0' }}</div>
        <div class='row_box'><span>互动数据授权异常：</span>{{ record.p2_fail_count || '0' }}</div>
      </div>
      <div slot="publish_time" slot-scope="text, record">
        <div class="time-box">
          <div>起始时间：</div>
          <div>{{ record.publish_time || '--' }}</div>
        </div>
        <div class="time-box">
          <div>结束时间：</div>
          <div>{{ record.finish_time || '--' }}</div>
        </div>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="jumpPage(record)">查看数据</a>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        :currentPage="current"
        :pageSize="page_size"
        :total="total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
  </a-card>
</template>

<script>
import moment from 'moment';
import buryEvent from '@/utils/buryEvent.js';

export default {
  data () {
    return {
      search_form: {
        //   begin_time: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        //   end_time: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        time: [moment().startOf('week'), moment().endOf('week')]
      },
      principal_id_list: [],
      columns: [
        {
          dataIndex: 'activity_name',
          width: 150,
          title: '名称'
        },
        {
          dataIndex: 'principal_name',
          width: 150,
          title: '品牌(主体)'
        },
        {
          dataIndex: 'dealer_count',
          scopedSlots: {customRender: 'dealer_count'},
          width: 150,
          title: '经销商数据'
        },
        /* {
           dataIndex: 'dealer_count',
           width: 150,
           title: '参与经销商数'
         },
         {
           dataIndex: 'media_count',
           width: 150,
           title: '媒体帐号数'
         },*/
        {
          dataIndex: 'publish_time',
          scopedSlots: {customRender: 'publish_time'},
          width: 150,
          title: '时间'
        },
        {
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
          width: 100,
          title: '操作'
        }
      ],
      data_list: [],
      loading: false,
      total: 0,
      current: 1,
      page_size: 10
    };
  },
  mounted () {
    this.getPrincipals();
    this.getList();
  },
  methods: {
    getPrincipals () {
      this.$api.core.TrainingList.getPrincipals().then(res => {
        if (Array.isArray(res)) {
          this.principal_id_list = res;
        } else {
          this.principal_id_list = [];
          // console.log('获取主体失败');
        }
      });
    },
    onChangePrincipal (val, option) {
      this.$set(this.search_form, 'principal_name', option.data.attrs.name);
    },
    //搜索
    bindSearch () {
      this.current = 1;
      this.getList();
    },
    //重置
    resetSearch () {
      this.search_form = {
        begin_time: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        time: [moment().startOf('week'), moment().endOf('week')]
      };
      this.current = 1;
      this.page_size = 10;
      this.getList();
    },
    //翻页
    onChange (current, page_size) {
      this.current = current;
      this.page_size = page_size;
      this.getList();
    },
    //日期选择框
    onChangeDate (dates) {
      let start_time = '';
      let end_time = '';
      if (dates[0]) {
        start_time = this.$moment(dates[0]._d).format('YYYY-MM-DD HH:mm:ss');
        end_time = this.$moment(dates[1]._d).format('YYYY-MM-DD HH:mm:ss');
      }

      this.search_form.start_time = start_time;
      this.search_form.end_time = end_time;
    },
    //检索列表
    getList () {
      this.loading = true;
      let params = {
        activity_name: this.search_form.activity_name,
        principal_name: this.search_form.principal_name,
        start_time: this.search_form.start_time,
        end_time: this.search_form.end_time,
        current: this.current,
        page_size: this.page_size
      };
      this.$api.core.TrainingList.getList(params).then(res => {
        this.loading = false;
        if (res.code === 0) {
          this.data_list = res.data.list || [];
          this.total = res.data.pagination.total || 0;
        } else {
          this.data_list = [];
          this.total = 0;
          // console.log('获取活动列表失败');
        }
      });
    },
    //跳转经销商列表
    jumpPage (record) {
      this.$router.push({
        path: `/training/account`,
        query: {
          activity_name: record.activity_name,
          activity_id: record.id,
          principal_id: record.principal_id
        }
      });
      buryEvent('activity', 'look-data', `${ record.activity_name }`);
    }
  }
};
</script>

<style lang="less" scoped>
.mt-40p {
  margin-top: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time-box {
  margin-bottom: 5px;
  text-align: left;

  div:first-child {
    font-weight: bold;
  }

  div:last-child {
    white-space: nowrap;
  }
}
</style>
